(function ($) {
	$(document).ready(function () {
		/**
		 * FOOTER
		 */

		/**
		 * Toggles the active state of the footer menu links when the footer menu is clicked.
		 * If the current footer menu's links are already active, they are deactivated.
		 * Otherwise, all footer menu links are deactivated and the current footer menu's links are activated.
		 */
		$(".footer-menu").on("click", function () {
			if ($(this).find(".footer-menu__links").hasClass("active")) {
				$(this).find(".footer-menu__links").removeClass("active");
			} else {
				$(".footer-menu__links").removeClass("active");
				$(this).find(".footer-menu__links").addClass("active");
			}
		});
	});
})(jQuery);
